import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Services from "../components/Services/Services"

const ServicesPage = () => (
  <Layout>
    <SEO title="Services" />
    <Services heading="Our Services" />
  </Layout>
)

export default ServicesPage
