import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FaCode } from "react-icons/fa"

import { ABtn } from "../Button"

import { motion } from "framer-motion"

const Services = ({ heading }) => {
  const data = useStaticQuery(graphql`
    query ServiceQuery {
      allServicesJson {
        edges {
          node {
            alt
            link
            linkto
            target
            name
            img {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  function getServices(sdata) {
    const serviceArray = []
    sdata.allServicesJson.edges.forEach((item, index) => {
      serviceArray.push(
        <ServiceCard
          key={index}
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          <ServiceImg
            alt={item.node.alt}
            fluid={item.node.img.childImageSharp.fluid}
          />
          <ServiceInfo>
            <TextWrap>
              <FaCode />
              <ServiceTitle>{item.node.name}</ServiceTitle>
            </TextWrap>
            <ABtn
              href={item.node.linkto}
              primaryA="true"
              roundA="true"
              target={item.node.target}
              css={`
                position: absolute;
                top: 420px;
                font-size: 14px;
              `}
            >
              {item.node.link}
            </ABtn>
          </ServiceInfo>
        </ServiceCard>
      )
    })

    return serviceArray
  }

  return (
    <ServicesContainer>
      <ServiceHeading>{heading}</ServiceHeading>
      <ServiceWrapper>{getServices(data)}</ServiceWrapper>
    </ServicesContainer>
  )
}

export default Services

const ServicesContainer = styled.div`
  min-height: 100vh;
  padding: 5em calc((100vw - 1300px) / 2);
  color: #fff;
`

const ServiceHeading = styled.div`
  font-size: clamp(1.2rem, 5vw, 3rem);
  text-align: center;
  margin-bottom: 5rem;
  color: #000;
`

const ServiceWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  justify-items: center;
  padding: 0 2rem;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 868px) {
    grid-template-columns: 1fr;
  }
`

const ServiceCard = styled(motion.div)`
  line-height: 2;
  width: 100%;
  height: 500px;
  position: relative;
  border-radius: 10px;
  transition: 0.2s ease;
`

const ServiceImg = styled(Img)`
  height: 100%;
  max-width: 100%;
  position: relative;
  border-radius: 10px;
  filter: brightness(70%);
  transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    filter: brightness(100%);
  }
`

const ServiceInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 2rem;

  @media screen and (max-width: 280px) {
    padding: 0 1rem;
  }
`

const TextWrap = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 375px;
`

const ServiceTitle = styled.div`
  font-weight: 400;
  font-size: 1rem;
  margin-left: 1rem;
`
